import React from 'react';
import ScoreButton from './ScoreButton.jsx';
import Score from './Score.jsx';
import Pegs from './Pegs.jsx';

function GuessRow(props) {
  return (
    <div className="GuessRow">
      <div className="rowNum" style={{ color: props.currentGuess ? 'black' : 'lightgrey' }}>
        {props.rowIdx + 1}
      </div>
      <Pegs
        colors={props.colors}
        code={props.guess.code}
        currentGuess={props.currentGuess}
        handlePegClick={props.handlePegClick}
      />
      {!props.defeat && props.currentGuess && props.guess.score.perfect !== 4 ? (
        <ScoreButton
          disabled={props.guess.code.includes(null)}
          handleScoreClick={props.handleScoreClick}
        />
      ) : (
        <Score score={props.guess.score} />
      )}
    </div>
  );
}

export default GuessRow;

import React from 'react';
import Row from './Row.jsx';

function GameBoard(props) {
  return (
    <div className="GameBoard">
      {props.guesses.map((guess, idx) => (
        <Row
          guess={guess}
          defeat={props.defeat}
          colors={props.colors}
          rowIdx={idx}
          currentGuess={idx === props.guesses.length - 1}
          handlePegClick={props.handlePegClick}
          handleScoreClick={props.handleScoreClick}
          key={idx}
        />
      ))}
    </div>
  );
}

export default GameBoard;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Confetti from 'react-confetti';
import LeaderboardController from '../Leaderboard';

const cardImagesQuery = graphql`
  query CardImagesQuery {
    allDatoCmsMatchingGameImage {
      edges {
        node {
          easyFrontImages {
            url
          }
          expertFrontImages {
            url
          }
          intermediateFrontImages {
            url
          }
          categories {
            name
            pairs {
              pairs {
                url
              }
            }
            columns
            rows
          }
          backImage {
            url
          }
        }
      }
    }
  }
`;

export default function MatchingGame() {
  const [rows, setRows] = React.useState(3);
  const [columns, setColumns] = React.useState(4);
  const [selectedMode, setSelectedMode] = React.useState('easy');
  const [board, setBoard] = React.useState([]);
  const [moveCount, setMoveCount] = React.useState(0);
  const [firstSelection, setFirstSelection] = React.useState([]);
  const [secondSelection, setSecondSelection] = React.useState([]);
  const [showCustomSize, setShowCustomSize] = React.useState(false);
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);

  const imageData = useStaticQuery(cardImagesQuery);
  const images = imageData?.allDatoCmsMatchingGameImage?.edges?.[0]?.node ?? {};

  const backImage =
    images?.backImage?.url ??
    'https://tekeye.uk/playing_cards/images/svg_playing_cards/backs/red.svg';

  const easyFrontImages = images?.easyFrontImages.map(({ url }) => url);
  const intermediateFrontImages = images?.intermediateFrontImages.map(({ url }) => url);
  const expertFrontImages = images?.expertFrontImages.map(({ url }) => url);
  const categories = images?.categories
    ?.map(({ name, rows, columns, pairs }) => ({
      name,
      rows,
      columns,
      pairs: pairs.map(({ pairs }) => pairs),
    }))
    .reduce((acc, category) => {
      const name = category.name;
      delete category.name;
      acc[name] = category;
      return acc;
    }, {});

  const generateBoard = (rows, columns, mode) => {
    const numPairs = (rows * columns) / 2;
    let cards;
    if (['easy', 'intermediate', 'expert'].includes(mode)) {
      cards = Array.from({ length: numPairs }, (_, i) => {
        if (mode === 'easy') return { image: easyFrontImages[i], index: i };
        if (mode === 'intermediate') return { image: intermediateFrontImages[i], index: i };
        if (mode === 'expert') return { image: expertFrontImages[i], index: i };
      }).flatMap((x) => [x, x]); // Create pairs of cards
    } else if (mode === 'custom') {
      const randomImages = [...easyFrontImages, ...intermediateFrontImages, ...expertFrontImages];
      shuffleArray(randomImages);
      cards = Array.from({ length: numPairs }, (_, i) => {
        return { image: randomImages[i], index: i };
      }).flatMap((x) => [x, x]); // Create pairs of cards
    } else {
      cards = Array.from({ length: numPairs }, (_, i) => {
        const category = categories[mode];
        const pair = category.pairs;
        return [
          { image: pair[i][0].url, index: i },
          { image: pair[i][1].url, index: i },
        ];
      }).flat();
    }
    shuffleArray(cards); // Shuffle the cards randomly

    const gameBoard = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < columns; j++) {
        const index = i * columns + j;
        row.push({ card: cards[index].image, id: cards[index].index, matched: false });
      }
      gameBoard.push(row);
    }

    setBoard(gameBoard);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const handleClick = (rowNum, colNum) => {
    if (!firstSelection.length) {
      setFirstSelection([rowNum, colNum]);
      return;
    } else if (!secondSelection.length) {
      if (rowNum === firstSelection[0] && colNum === firstSelection[1]) return;
      setSecondSelection([rowNum, colNum]);
      setMoveCount((prev) => prev + 1);
      setTimeout(() => {
        const boardCopy = structuredClone(board);
        if (boardCopy[firstSelection[0]][firstSelection[1]].id === boardCopy[rowNum][colNum].id) {
          boardCopy[firstSelection[0]][firstSelection[1]].matched = true;
          boardCopy[rowNum][colNum].matched = true;
        }
        setBoard(boardCopy);
        setFirstSelection([]);
        setSecondSelection([]);
      }, 1000);
    }
  };

  const updateMode = (mode) => {
    setSelectedMode(mode);
    if (mode === 'easy') {
      setRows(3);
      setColumns(4);
      generateBoard(3, 4, 'easy');
    } else if (mode === 'intermediate') {
      setRows(4);
      setColumns(5);
      generateBoard(4, 5, 'intermediate');
    } else if (mode === 'expert') {
      setRows(5);
      setColumns(6);
      generateBoard(5, 6, 'expert');
    } else if (mode === 'custom') {
      generateBoard(rows, columns, mode);
    } else {
      const category = categories[mode];
      setRows(category.rows);
      setColumns(category.columns);
      generateBoard(category.rows, category.columns, mode);
    }
    setFirstSelection([]);
    setSecondSelection([]);
    setMoveCount(0);
  };

  const reset = () => {
    setShowLeaderboard(false);
    generateBoard(rows, columns, selectedMode);
    setFirstSelection([]);
    setSecondSelection([]);
    setMoveCount(0);
  };

  React.useEffect(() => {
    generateBoard(rows, columns, selectedMode);
  }, []);

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) return;
    const decodedToken = JSON.parse(atob(token));
    setMoveCount(decodedToken.score);
    setShowLeaderboard(true);
    localStorage.removeItem('redirect');
  }, []);

  const hasWon = board.flat().every((card) => card.matched);

  React.useEffect(() => {
    if (board.length && hasWon) setShowLeaderboard(true);
  }, [hasWon]);

  return (
    <div className="matching-container">
      {hasWon && (
        <div className="confetti-container">
          <Confetti />
        </div>
      )}
      {showLeaderboard && (
        <LeaderboardController
          game={`memoryGame${(selectedMode.charAt(0).toUpperCase() + selectedMode.slice(1))
            .split(' ')
            .join('')}`}
          score={hasWon ? moveCount : 0}
          type="score"
          onClose={() => setShowLeaderboard(false)}
        />
      )}

      <p>Levels</p>
      <div className="srow mb-1 x-center">
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('easy')}>
            Easy
          </button>
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('intermediate')}>
            Intermediate
          </button>
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('expert')}>
            Expert
          </button>
        </div>
        <div className="scolumn narrow custom-column">
          <button className="button" onClick={() => setShowCustomSize((prev) => !prev)}>
            Custom
          </button>
          {showCustomSize && (
            <div className="custom-size-container">
              <label htmlFor="">Rows</label>
              <input className="input" value={rows} onChange={(e) => setRows(e.target.value)} />
              <label htmlFor="">Columns</label>
              <input
                className="input"
                value={columns}
                onChange={(e) => setColumns(e.target.value)}
              />
              <div className="flex controls">
                <button
                  className="button"
                  onClick={() => {
                    if (!rows || !columns) return;
                    if ((rows * columns) % 2 === 1) return;
                    if (
                      [...easyFrontImages, ...intermediateFrontImages, ...expertFrontImages]
                        .length <
                      (rows * columns) / 2
                    ) {
                      return;
                    } else {
                      setMoveCount(0);
                      setFirstSelection([]);
                      setSecondSelection([]);
                      updateMode('custom');
                      generateBoard(rows, columns, 'custom');
                      setShowCustomSize(false);
                    }
                  }}
                >
                  Create
                </button>
                <button className="button" onClick={() => setShowCustomSize(false)}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => reset()}>
            Reset
          </button>
        </div>
      </div>
      {Object.keys(categories).length && <p>Topics</p>}
      <div className="srow mb-1 x-center">
        {Object.keys(categories).map((category) => (
          <div className="scolumn narrow">
            <button className="button" onClick={() => updateMode(category)}>
              {category}
            </button>
          </div>
        ))}
      </div>
      <div className="srow mb-1 x-center">
        <div className="scolumn narrow">
          <button className="button" onClick={() => setShowLeaderboard(true)}>
            Leaderboard
          </button>
        </div>
      </div>
      <p>Move count: {moveCount}</p>
      <div className="game-container">
        {board.map((row, rowNum) => (
          <div className="matching-row">
            {row.map((cell, colNum) => (
              <div
                className={`card ${
                  (rowNum === firstSelection[0] && colNum === firstSelection[1]) ||
                  (rowNum === secondSelection[0] && colNum === secondSelection[1]) ||
                  cell.matched
                    ? 'selected'
                    : ''
                }`}
                onClick={() => handleClick(rowNum, colNum)}
              >
                <div className="card-side back">
                  <img src={cell.card} />
                </div>
                <div className="card-side front">
                  <img src={backImage} alt="" />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import Confetti from 'react-confetti';

import BombSvg from './../../../assets/icons/bomb.svg';
import FlagSvg from './../../../assets/icons/flag.svg';
import HappyFaceSvg from './../../../assets/icons/happy-face.svg';
import SadFaceSvg from './../../../assets/icons/sad-face.svg';

import LeaderboardController from '../../Leaderboard';

export default function Minesweeper() {
  const [width, setWidth] = React.useState(8);
  const [height, setHeight] = React.useState(8);
  const [board, setBoard] = React.useState([]);
  const [timer, setTimer] = React.useState(null);
  const [activeTimer, setActiveTimer] = React.useState();
  const [numberOfBombs, setNumberOfBombs] = React.useState(10);
  const [selectedMode, setSelectedMode] = React.useState('easy');
  const [hasLost, setHasLost] = React.useState(false);
  const [showCustomSize, setShowCustomSize] = React.useState(false);
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);

  const getRandomBombs = (amount, columns, rows, starter = null) => {
    const bombsArray = [];
    const limit = columns * rows;
    const bombsPool = [...Array(limit).keys()];

    if (starter > 0 && starter < limit) {
      bombsPool.splice(starter, 1);
    }

    for (let i = 0; i < amount; ++i) {
      const n = Math.floor(Math.random() * bombsPool.length);
      bombsArray.push(...bombsPool.splice(n, 1));
    }

    return [11, 12, 13, 14, 15, 16, 17, 18, 21, 22];
    return bombsArray;
  };

  const getNeighbours = (grid, y, x, withoutDiag = false) => {
    const neighbours = [];
    const currentRow = grid[y];
    const prevRow = grid[y - 1];
    const nextRow = grid[y + 1];

    if (currentRow[x - 1]) neighbours.push(currentRow[x - 1]);
    if (currentRow[x + 1]) neighbours.push(currentRow[x + 1]);
    if (prevRow) {
      if (prevRow[x - 1] && !withoutDiag) neighbours.push(prevRow[x - 1]);
      if (prevRow[x]) neighbours.push(prevRow[x]);
      if (prevRow[x + 1] && !withoutDiag) neighbours.push(prevRow[x + 1]);
    }
    if (nextRow) {
      if (nextRow[x - 1] && !withoutDiag) neighbours.push(nextRow[x - 1]);
      if (nextRow[x]) neighbours.push(nextRow[x]);
      if (nextRow[x + 1] && !withoutDiag) neighbours.push(nextRow[x + 1]);
    }

    return neighbours;
  };
  const generateBoard = (width, height, numberOfBombs) => {
    const boardToGenerate = [];
    const bombsToAdd = getRandomBombs(numberOfBombs, width, height);

    for (let i = 0; i < height; ++i) {
      boardToGenerate.push([]);
      for (let j = 0; j < width; ++j) {
        boardToGenerate[i][j] = {
          isBomb: bombsToAdd.includes(i * width + j),
          isClicked: false,
          isFlagged: false,
          numOfBombsAdjacent: 0,
          x: j,
          y: i,
        };
      }
    }

    for (let i = 0; i < height; ++i) {
      boardToGenerate.push([]);
      for (let j = 0; j < width; ++j) {
        const neighbours = getNeighbours(boardToGenerate, i, j);
        boardToGenerate[i][j].numOfBombsAdjacent = neighbours.reduce(
          (acc, neighbour) => (acc += neighbour.isBomb ? 1 : 0),
          0,
        );
      }
    }

    setBoard(boardToGenerate);
  };
  React.useEffect(() => startGame(), [selectedMode]);

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (!token) return;
    const decodedToken = JSON.parse(atob(token));
    setShowLeaderboard(true);
    setTimer(decodedToken.time);
    localStorage.removeItem('redirect');
  }, []);

  const updateMode = (mode) => {
    setSelectedMode(mode);
    if (mode === 'easy') {
      setWidth(8);
      setHeight(8);
      setNumberOfBombs(10);
    } else if (mode === 'intermediate') {
      setWidth(16);
      setHeight(16);
      setNumberOfBombs(40);
    } else if (mode === 'expert') {
      setWidth(20);
      setHeight(20);
      setNumberOfBombs(99);
    }
    startGame();
  };

  const startGame = () => {
    setShowLeaderboard(false);
    setHasLost(false);
    setTimer(null);
    generateBoard(width, height, numberOfBombs);
  };

  React.useEffect(() => {
    if (timer === null || showLeaderboard) return;
    clearTimeout(activeTimer);
    const timeout = setTimeout(() => setTimer((prev) => prev + 1), 1000);
    setActiveTimer(timeout);
  }, [timer]);

  const handleClick = (x, y) => {
    if (timer === null) setTimer(0);
    if (hasLost) return;
    const cell = board[x][y];
    if (cell.isClicked) return;
    const boardCopy = structuredClone(board);
    boardCopy[x][y].isClicked = true;
    boardCopy[x][y].isFlagged = false;
    if (cell.numOfBombsAdjacent === 0 && !cell.isBomb) {
      let neighbours = getNeighbours(boardCopy, x, y);
      while (neighbours.length) {
        const neighbour = neighbours.shift();
        if (neighbour.isClicked) continue;
        if (!neighbour.isBomb && !neighbour.isFlagged) {
          boardCopy[neighbour.y][neighbour.x].isClicked = true;
          if (neighbour.numOfBombsAdjacent === 0) {
            neighbours = [
              ...neighbours,
              ...getNeighbours(boardCopy, neighbour.y, neighbour.x, true),
            ];
          }
        }
      }
    }

    setBoard(boardCopy);
    if (cell.isBomb) {
      setHasLost(true);
      for (let i = 0; i < boardCopy.length; i++) {
        for (let j = 0; j < boardCopy[i].length; j++) {
          if (boardCopy[i][j].isBomb) {
            boardCopy[i][j].isClicked = true;
          }
        }
      }
      setBoard(boardCopy);
      clearTimeout(activeTimer);
    }
  };

  const handleRightClick = (e, x, y) => {
    e.preventDefault();
    if (hasLost) return;
    const boardCopy = structuredClone(board);
    boardCopy[x][y].isFlagged = !boardCopy[x][y].isFlagged;
    setBoard(boardCopy);
  };

  const hasWon = board.every((row) =>
    row.every((cell) => (cell.isBomb && !cell.isClicked) || (!cell.isBomb && cell.isClicked)),
  );

  React.useEffect(() => {
    if (board.length && hasWon) setShowLeaderboard(true);
  }, [hasWon]);

  if (hasWon) {
    clearTimeout(activeTimer);
  }

  const flagsPlaced = board.reduce(
    (acc1, row) => (acc1 += row.reduce((acc2, cell) => (acc2 += cell.isFlagged ? 1 : 0), 0)),
    0,
  );

  const bombsToFind = numberOfBombs - flagsPlaced;

  return (
    <div className="minesweeper-container x-center">
      {hasWon && (
        <div className="confetti-container">
          <Confetti />
        </div>
      )}
      {showLeaderboard && (
        <LeaderboardController
          game={`minesweeper${selectedMode.charAt(0).toUpperCase() + selectedMode.slice(1)}`}
          time={timer}
          type="time_taken"
          onClose={() => setShowLeaderboard(false)}
        />
      )}
      <div className="srow mb-1 x-center">
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('easy')}>
            Easy
          </button>
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('intermediate')}>
            Intermediate
          </button>
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => updateMode('expert')}>
            Expert
          </button>
        </div>
        <div className="scolumn narrow custom-column">
          <button className="button" onClick={() => setShowCustomSize((prev) => !prev)}>
            Custom
          </button>
          {showCustomSize && (
            <div className="custom-size-container">
              <label htmlFor="">Width</label>
              <input className="input" value={width} onChange={(e) => setWidth(e.target.value)} />
              <label htmlFor="">Height</label>
              <input className="input" value={height} onChange={(e) => setHeight(e.target.value)} />
              <label htmlFor="">Bomb count</label>
              <input
                className="input"
                value={numberOfBombs}
                onChange={(e) => setNumberOfBombs(e.target.value)}
              />

              <div className="flex controls">
                <button
                  className="button"
                  onClick={() => {
                    if (numberOfBombs > width * height) startGame();
                    else {
                      setHasLost(false);
                      setTimer(null);
                      generateBoard(width, height, numberOfBombs);
                      setShowCustomSize(false);
                    }
                  }}
                >
                  Create
                </button>
                <button className="button" onClick={() => setShowCustomSize(false)}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="scolumn narrow">
          <button className="button" onClick={() => setShowLeaderboard(true)}>
            Leaderboard
          </button>
        </div>
      </div>
      <div className="srow x-center mb-1">
        <div className="scolumn narrow">
          <img
            className="lost-indicator"
            src={hasLost ? SadFaceSvg : HappyFaceSvg}
            onClick={startGame}
          />
        </div>
      </div>
      <div className="srow x-center mb-1">
        <div className="scolumn narrow">
          <p>Number of bombs: {numberOfBombs}</p>
          <p>
            Time taken: {Math.floor(timer / 60)} minutes, {timer % 60} seconds
          </p>
        </div>
      </div>
      <div
        className="minesweeper-board"
        style={{
          gridTemplateRows: `repeat(${height}, 1fr)`,
          gridTemplateColumns: `repeat(${width}, 1fr)`,
        }}
      >
        {board.map((row, x) => (
          <div className="row x-center">
            {row.map((cell, y) => (
              <div
                className={`cell ${cell.isClicked ? 'non-empty' : null}`}
                onClick={() => handleClick(x, y)}
                onContextMenu={(e) => handleRightClick(e, x, y)}
              >
                {cell.isFlagged ? (
                  <img src={FlagSvg} />
                ) : cell.isClicked ? (
                  cell.isBomb ? (
                    <img src={BombSvg} />
                  ) : (
                    cell.numOfBombsAdjacent || ''
                  )
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

import React from 'react';
import Confetti from 'react-confetti';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import LeaderboardController from '../Leaderboard';

const numberToText = {
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
};

const colours = [
  '#fd99c5',
  '#e81416',
  '#ffa500',
  '#faeb36',
  '#79c314',
  '#487de7',
  '#4b369d',
  '#70369d',
];

const isBrowser = typeof window !== 'undefined';

export default function TowersOfHanoi() {
  const [dragId, setDragId] = React.useState();
  const [numberOfDisks, setNumberOfDisks] = React.useState(5);
  const [movesTaken, setMovesTaken] = React.useState(0);
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);
  const [disks, setDisks] = React.useState(
    new Array(numberOfDisks).fill(null).map((_, i) => ({
      id: `tile${i + 1}`,
      rod: 1,
      position: i + 1,
      width: 3 + i * 1.5,
      color: colours[i],
    })),
  );

  const rod1Disks = disks.filter((disk) => disk.rod === 1);
  const rod2Disks = disks.filter((disk) => disk.rod === 2);
  const rod3Disks = disks.filter((disk) => disk.rod === 3);

  const hasWon = disks.every((tile) => tile.rod === 3);

  React.useEffect(() => {
    if (hasWon) setShowLeaderboard(true);
  }, [hasWon]);

  React.useEffect(() => {
    if (isBrowser) {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) return;
      const decodedToken = JSON.parse(atob(token));
      setMovesTaken(decodedToken.score);
      setShowLeaderboard(true);
      localStorage.removeItem('redirect');
    }
  }, []);

  const handleDrop = (width, rod) => {
    const dragDisk = disks.find((disk) => disk.width === width);
    const originalRod = dragDisk.rod;
    const validMove = disks.every((disk) => {
      if (disk.rod !== originalRod) return true;
      if (disk.position < dragDisk.position) return false;
      return true;
    });

    if (!validMove) {
      return;
    }

    const dropColumnTopDisk = disks
      .filter((disk) => disk.rod.toString() === rod.toString())
      .sort((a, b) => a.width - b.width)[0];

    let newDiskState = disks;

    if (!dropColumnTopDisk || dragDisk.width < dropColumnTopDisk.width) {
      newDiskState = disks.map((disk) => {
        if (disk.id === dragDisk.id) {
          disk.rod = parseInt(rod, 10);
        }

        return disk;
      });
      setMovesTaken((prev) => prev + 1);
    }

    setDisks(newDiskState);
  };

  const handleDiskCountChange = (diskCount) => {
    setNumberOfDisks(diskCount);
    const newDisks = new Array(diskCount).fill(null).map((_, i) => ({
      id: `tile${i + 1}`,
      rod: 1,
      position: i + 1,
      width: 3 + i * 1.5,
      color: colours[i],
    }));
    setDisks(newDisks);
    setMovesTaken(0);
  };

  const resetGame = () => {
    setShowLeaderboard(false);
    const newDisks = new Array(numberOfDisks).fill(null).map((_, i) => ({
      id: `tile${i + 1}`,
      rod: 1,
      position: i + 1,
      width: 3 + i * 1.5,
      color: colours[i],
    }));
    setDisks(newDisks);
    setMovesTaken(0);
  };

  if (!isBrowser) return null;
  const isMobile = window.innerWidth < 600;

  function Rod({ children, index }) {
    const [, drop] = useDrop({
      accept: 'Disk',
      drop: () => ({ rod: index }),
      canDrop: () => true,
    });

    return (
      <div className="rod-container" ref={drop}>
        <div className="rod" />
        {children}
      </div>
    );
  }

  function Disk({ width, color, name }) {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
      accept: 'Disk',
      hover: (item, monitor) => {
        if (!ref.current) {
          return;
        }
      },
    });

    const [, drag] = useDrag({
      item: { width, color, name },
      type: 'Disk',
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        handleDrop(width, dropResult.rod);
      },
    });

    drag(drop(ref));

    return (
      <div ref={ref} className="disk" style={{ width: `${width}rem`, backgroundColor: color }} />
    );
  }

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className="container-flex flex-col">
        {hasWon && (
          <div className="confetti-container">
            <Confetti />
          </div>
        )}
        {showLeaderboard && (
          <LeaderboardController
            game={`towersOfHanoi${numberToText[numberOfDisks]}Disk`}
            score={hasWon ? movesTaken : 0}
            onClose={() => setShowLeaderboard(false)}
          />
        )}
        <div className="srow mb-1 x-between">
          <div className="scolumn narrow">
            <label htmlFor="number-of-disks" className="mb-0">
              Number of disks
            </label>
            <select
              className="dropdown"
              name="number-of-disks"
              id="number-of-disks"
              value={numberOfDisks}
              onChange={(e) => handleDiskCountChange(parseInt(e.target.value))}
            >
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </select>
          </div>
          <div className="scolumn narrow">
            <p>Moves taken: {movesTaken}</p>
          </div>
        </div>
        <div className="towers-of-hanoi-container">
          <Rod index={1}>
            {rod1Disks
              .sort((a, b) => a.position - b.position)
              .map((disk) => (
                <Disk width={disk.width} color={disk.color} name={`rod-${1}-${disk.width}`} />
              ))}
          </Rod>
          <Rod index={2}>
            {rod2Disks
              .sort((a, b) => a.position - b.position)
              .map((disk) => (
                <Disk width={disk.width} color={disk.color} name={`rod-${2}-${disk.width}`} />
              ))}
          </Rod>
          <Rod index={3}>
            {rod3Disks
              .sort((a, b) => a.position - b.position)
              .map((disk) => (
                <Disk width={disk.width} color={disk.color} name={`rod-${3}-${disk.width}`} />
              ))}
          </Rod>
        </div>
        <div className="srow my-1 x-between">
          <div className="scolumn">
            <button className="button" onClick={resetGame}>
              Reset
            </button>
            <button className="button mt-0.5" onClick={() => setShowLeaderboard(true)}>
              Leaderboard
            </button>
          </div>
          <div className="scolumn narrow">
            <p className="minimum-moves">Minimum moves {Math.pow(2, numberOfDisks) - 1}</p>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}
